import './App.css';
import { ThemeProvider } from '@material-ui/styles';
import UItheme from "@bamboobox/b2-theme";
import { AuthProvider } from "@bamboobox/b2logincheck";
import {ResourceCheck} from '@bamboobox/b2resource-check/dist'
import { HashRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import React,{useEffect} from 'react';
import { lazy } from 'react';
import { Suspense } from 'react';
import { CircularProgress } from '@mui/material';
const ExportStart = lazy(()=>import('./ExportStart'));

function App() {
  
//     let location = useLocation();
//     function useQuery() {
//         return new URLSearchParams(location.search);
//     }
//     let GetIcp = () => {
//         return useQuery().get("icp") || '';
//     }
//     let params_url = useQuery().get("icp") || "";
//     const [icp, setIcp] = useState(params_url);


//     React.useEffect(() => {
//       console.log("test icp query changed")
//       setIcp(GetIcp())
//   }, [useQuery().get("icp")])

    const resources=["export_manager"]

    return (
      <div className="App">
        <AuthProvider
          loginURL={window._env_.LOGIN_URL}
          authcheckURL={window._env_.AUTH_URL}
        >
          <ThemeProvider theme={UItheme}>
            <Suspense
              fallback={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "57vh",
                  }}
                >
                  <CircularProgress style={{ color: "#21DA8C" }} />
                </div>
              }
            >
              <ResourceCheck
                component={<ExportStart />}
                resources={resources}
              />
              {/* <ExportStart/> */}
            </Suspense>
          </ThemeProvider>
        </AuthProvider>
      </div>
    );
}

export default App;
